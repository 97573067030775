section {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.waifu-pic {
    height: 450px;
}

.waifu-icon {
    width: 48px;
    margin-bottom: 12px;
    transition: all .2s;
}

.waifu-icon:hover {
    animation: icon-ani .6s ;
}

button {
    background-color: transparent;
    border: none;
}

.refresh {
    width: 56px;
    margin-top: 20px;
}

.refresh:hover {
    animation: refresh-icon .6s;
}


@keyframes icon-ani {   
    50% {
        transform: scale(1.02)  translateY(4px);        
    }
    75% {
        transform: scale(1.05)  translateY(-4px);        
    }
    100% {
        transform: scale(1) translate(0);
    }    
}

@keyframes refresh-icon {

    50% {
        transform: scale(1.02)  rotate(20deg);        
    }
    75% {
        transform: scale(1.05)  rotate(-20deg);        
    }
    100% {
        transform: scale(1) translate(0);
    }    
    
}